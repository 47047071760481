// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_rL d_gv d_cs";
export var heroHeaderCenter = "t_gw d_gw d_cs d_dw";
export var heroHeaderRight = "t_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "t_rM d_gr d_cw";
export var heroParagraphCenter = "t_gs d_gs d_cw d_dw";
export var heroParagraphRight = "t_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "t_rN d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "t_rP d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "t_rQ d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "t_rR d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "t_rS d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "t_rT y_rT";
export var heroExceptionNormal = "t_rV y_rV";
export var heroExceptionLarge = "t_rW y_rW";
export var Title1Small = "t_rX y_rX y_sn y_sp";
export var Title1Normal = "t_rY y_rY y_sn y_sq";
export var Title1Large = "t_rZ y_rZ y_sn y_sr";
export var BodySmall = "t_r0 y_r0 y_sn y_sH";
export var BodyNormal = "t_r1 y_r1 y_sn y_sJ";
export var BodyLarge = "t_r2 y_r2 y_sn y_sK";