// extracted by mini-css-extract-plugin
export var alignLeft = "v_qh d_fp d_bG d_dv";
export var alignCenter = "v_bP d_fq d_bD d_dw";
export var alignRight = "v_qj d_fr d_bH d_dx";
export var alignColumnLeft = "v_q7 d_fs d_bN d_dv";
export var alignColumnCenter = "v_q8 d_ft d_bP d_dw";
export var alignColumnRight = "v_q9 d_fv d_bQ d_dx";
export var milestonesContainer = "v_r3 d_dW";
export var milestonesContainerFull = "v_r4 d_dT";
export var milestonesSubtitle = "v_mf d_mf d_cs";
export var milestonesComponentWrapper = "v_mb d_mb d_cv";
export var compContentWrapper = "v_rd d_mc d_bz d_bJ d_bM d_bF d_c7 d_H";
export var milestonesMainHeader = "v_l8 d_l8 d_w d_cr";
export var milestonesSubHeader = "v_l9 d_l9 d_w";
export var milestonesComponentWrapperRow = "v_r5 d_H";
export var milestonesBlockWrapper = "v_r6";
export var milestonesTextBlockWrapper = "v_r7";
export var milestonesComponentHeader = "v_md d_md d_w d_cr";
export var milestonesComponentParagraph = "v_mj d_mj d_w";
export var btnWrapper = "v_d2 d_bz d_ck";
export var btnWrapperCards = "v_rg d_bz d_cd";
export var imageWrapper = "v_r8 d_cs d_bz";
export var Title1Small = "v_rX y_rX y_sn y_sp";
export var Title1Normal = "v_rY y_rY y_sn y_sq";
export var Title1Large = "v_rZ y_rZ y_sn y_sr";
export var Title2Small = "v_r9 y_r9 y_sn y_ss";
export var Title2Normal = "v_sb y_sb y_sn y_st";
export var Title2Large = "v_sc y_sc y_sn y_sv";
export var SubtitleSmall = "v_qd y_qd y_sn y_sz";
export var SubtitleNormal = "v_qf y_qf y_sn y_sB";
export var SubtitleLarge = "v_qg y_qg y_sn y_sC";
export var Subtitle2Small = "v_sd y_sd y_sn y_sD";
export var Subtitle2Normal = "v_sf y_sf y_sn y_sF";
export var Subtitle2Large = "v_sg y_sg y_sn y_sG";
export var BodySmall = "v_r0 y_r0 y_sn y_sH";
export var BodyNormal = "v_r1 y_r1 y_sn y_sJ";
export var BodyLarge = "v_r2 y_r2 y_sn y_sK";
export var exceptionLineHeight = "v_sh y_sR";